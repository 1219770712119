import type { PassThrough } from '@primevue/core';
import type { DatePickerPassThroughOptions } from 'primevue/datepicker';

export const datePickerPassthrough: PassThrough<DatePickerPassThroughOptions> =
  {
    pcInput: {
      root: {
        class:
          'w-full bg-light-200 dark:bg-dark-800 pl-2 py-2.5 rounded-lg std-border text-base-priority',
      },
    },
    dropdown: { class: 'absolute right-[10px] bottom-[10px]' },
    dropdownIcon: { class: 'text-lg text-base-priority' },
    panel: {
      class:
        'w-fit bg-layer-1 text-base-priority rounded-lg std-border !top-[318px]',
    },
    header: {
      class:
        'flex flex-row justify-between gap-2 content-center mb-2 py-2 px-4 border-b std-border-color',
    },
    pcPrevButton: { root: { class: 'flex items-center font-bold' } },
    pcNextButton: { root: { class: 'flex items-center font-bold' } },
    calendarContainer: { class: 'w-full mb-3' },
    dayView: { class: 'w-full' },
    dayCell: { class: 'text-center cursor-pointer' },
    title: { class: 'flex gap-4 font-bold' },
    monthView: { class: 'grid grid-cols-3 justify-items-center mb-3' },
    month: { class: 'font-bold cursor-pointer w-min' },
    yearView: { class: 'grid grid-cols-2 justify-items-center mb-3' },
    year: { class: 'font-bold cursor-pointer w-min' },

    day: ({ context }) => ({
      class: [
        {
          'text-base-disabled cursor-not-allowed': context.disabled,
        },
      ],
    }),
  };
