import type { PassThrough } from '@primevue/core';
import type { PasswordPassThroughOptions } from 'primevue/password';

export const passwordPassThrough: PassThrough<PasswordPassThroughOptions> = {
  overlay: ({ state }) => ({
    class: [
      'std-border rounded-lg',
      state?.meter?.strength === 'strong'
        ? 'hide-password-popup'
        : 'show-password-popup',
    ],
  }),
  pcInputText: ({ state }) => ({
    root: ({ parent }) => ({
      class: [
        'w-full !px-4 dark:text-white dark:bg-dark-800 bg-light-200 text-base-priority text-sm std-border rounded-lg',
        parent?.instance?.modelValue?.length > 0 ? 'pt-[18px] pb-1.5' : 'py-3',
        {
          //error border
          '!border-error-500':
            parent?.props?.feedback &&
            state?.meter?.strength !== 'strong' &&
            parent?.instance?.modelValue !== '',
        },
      ],
    }),
  }),
  meterlabel: ({ instance }) => ({
    class: [
      {
        'bg-error-500': instance?.meter?.strength == 'weak',
        'bg-warning-500': instance?.meter?.strength == 'medium',
        'bg-primary-blue-gradient': instance?.meter?.strength == 'strong',
      },
      'rounded-lg h-[6px] z-5  transition-all duration-700 ease-out w-0',
    ],
  }),
  root: { class: 'w-full' },
  info: { class: 'hidden' },
  meter: { class: 'mx-2 h-[6px] rounded-lg bg-base mb-2 ' },
  meterText: { class: 'hidden' },
  transition: {
    enterFromClass: 'opacity-0 scale-y-[0.8]',
    enterActiveClass:
      'transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]',
    leaveActiveClass: 'transition-opacity duration-100 ease-linear',
    leaveToClass: 'opacity-0',
  },
};
